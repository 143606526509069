<template>
    <div class="page">
        <el-backtop><i class="el-icon-caret-top"></i></el-backtop>
        <img src="../../assets/images/product/nft-til.png" alt="" srcset="" class="til">
        <img src="../../assets/images/wenli-3.png" alt="" srcset="" class="wl3">
        <div class="cons">
            <img src="../../assets/images/product/nft1.jpg" alt="" srcset="">
            <img src="../../assets/images/product/nft2.jpg" alt="" srcset="">
            <img src="../../assets/images/product/nft3.jpg" alt="" srcset="">
            <img src="../../assets/images/product/nft4.jpg" alt="" srcset="">
            <img src="../../assets/images/product/nft5.jpg" alt="" srcset="">
            <img src="../../assets/images/product/nft6.jpg" alt="" srcset="">
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    created(){
    },
    methods: {
    }
    
}
</script>
<style lang="scss" scoped>
.page{
    background: #F8F9FA;
    position: relative;
    // display: inline-block;
    // width: 100%;
    .til{
        width: 322px;
        height: 156px;
        position: absolute;
        top: 104px;
        left: 138px;
        z-index: 1;
    }
    .wl3{
        width: 1146px;
        height: 1043px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .cons{
        width: 1082px;
        margin-top: 104px;
        margin-left: 656px;
        margin-bottom: 72px;
        img{
            width: 100%;
            display: block;
        }
    }
}
</style>